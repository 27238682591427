<template>
	<div>
		<div class="ebradi-checkbox" @click="openText">
			<div class="label">
				<svg
					v-if="texto !== null"
					width="10"
					height="8"
					viewBox="0 0 10 8"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.152 6.6432L0.956249 1.53C0.539969 0.863951 1.01881 0 1.80425 0H8.19575C8.98119 0 9.46003 0.86395 9.04375 1.53L5.848 6.6432C5.45633 7.26987 4.54367 7.26987 4.152 6.6432Z"
						fill="#33C1D5"
					/>
				</svg>
				<div v-else class="ball" />
				<label :for="name">{{ label }}</label>
			</div>
			<b-checkbox
				:disabled="scrollToAllow ? isDisabled : false"
				:id="name"
				v-model="checkbox"
				:name="name"
			/>
		</div>
		<div
			v-if="texto !== null && view"
      class="texto"
      v-html="texto"
      @scroll="handleScroll"
      ref="scrollContainer"
		/>
		<p
			v-if="errors.length > 0"
			class="help is-danger"
		>
			{{ errors[0] }}
		</p>
	</div>
</template>

<script>
export default {
  name: 'CheckBoxComponentEBRADI',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    scrollToAllow: {
      type: Boolean,
			required: false,
			default: false
		},
		texto: {
			type: String,
			required: false,
			default: null
		},
		errors: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data() {
		return {
			checkbox: false,
			view: false,
			isDisabled: true,
		}
	},
	watch: {
		value(value) {
			this.checkbox = value
		},
		checkbox(value) {
			this.$emit('input', value)
		}
	},
	mounted() {
		this.checkbox = this.value;
    if (!this.scrollToAllow) {
      this.isDisabled = false;
    }
	},
	methods: {
		openText(event) {
			if (event.target.nodeName !== 'SPAN') {
				this.view = !this.view
			}
		},
		handleScroll() {
      const container = this.$refs.scrollContainer;
      const scrollDiff =
        container.scrollHeight - container.scrollTop - container.clientHeight;
      if (scrollDiff <= 1) {
        this.isDisabled = false;
      }
    },
	}
}
</script>

<style lang="scss" scoped>
$background: #14181A;

.ebradi-checkbox {
	background: $background;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 16px;
	border-radius: 12px;
	cursor: pointer!important;

	.label {
		display: flex;
		align-items: center;
		margin: 0px;
		cursor: pointer!important;

		.ball {
			display: block;
			border-radius: 4px;
			height: 8px;
			background: var(--turquoise-blue);
			width: 8px;
			cursor: pointer!important;
		}

		label {
			cursor: pointer!important;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: var(--medium-gray);
			margin-left: 15px;
		}
	}
}

.texto {
	display: block;
	overflow-y: auto;
	margin: 22px 22px;
	padding: 22px;
	height: 262px;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}
}

</style>
