<template>
    <form
        name="form-cartao"
        class="form-cartao"
        @click="() => false"
    >
        <h3>Dados</h3>
        <fieldset class="inputs">
            <b-field
				label="Nome impresso no cartão"
                class="input-nome-cartao"
			>
				<b-input v-model="form.nome_cartao" />
			</b-field>
            <b-field
				label="Número do cartão"
                class="input-numero-cartao"
			>
				<b-input 
                    v-model="form.numero_cartao"
                    v-mask="'####-####-####-####'"
                />
			</b-field>
            <b-field
				label="Validade do cartão"
                class="input-validade"
			>
				<b-input v-model="form.validadae" v-mask="'##/####'" />
			</b-field>
            <b-field
				label="CVV"
                class="input-cvv"
			>
				<b-input v-model="form.cvv" v-mask="'###'" />
			</b-field>
        </fieldset>
    </form>
</template>

<script>
export default {
    name: 'FormCartaoComponentEBRADI',
    data() {
        return {
            form: {
                nome_cartao: null,
                numero_cartao: null,
                validadae: null,
                cvv: null
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-cartao {
    h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #B2B2B2;
    }

    .inputs {
        display: flex;
        margin-top: 10px;
        margin-bottom: 22px;
        justify-content: space-between;
        align-items: flex-start;
        
        .input-nome-cartao {
            height: 60px;
            width: 100%;
            max-width: 464px;
        }

        .input-numero-cartao {
            max-width: 282px;
            width: 100%;
        }

        .input-validade {
            max-width: 128px;
            width: 100%;
        }

        .input-cvv {
            max-width: 72px;
            width: 100%;
        }
    }


    ::v-deep .field {
        border: solid 1px #B2B2B2;
        border-radius: 12px;
        padding: 10px 16px;

        .label {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            margin: 0px;
            color: #B2B2B2;
        }

        .input {
            font-size: 14px;
            border: none!important;
            padding: 0px!important;
            height: auto!important;

            &:focus {
                box-shadow: none!important;
            }
        }
    }
}
</style>