<template>
	<ValidationObserver
		ref="form_dados_pessoais"
	>
		<form
			class="form-dados-pessoais"
			@submit="() => false"
		>
			<h2>Dados pessoais</h2>
			<fieldset
				v-if="!view"
			>
				<div class="columns is-multiline">
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="Nome"
							rules="required|max:128|min:8"
						>
							<b-field
								label="Nome"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input v-model="form.name" />
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="Telefone"
							rules="required"
						>
							<b-field
								label="Telefone"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input
									v-model="form.phone"
									v-mask="['(##) ####-####', '(##) # ####-####']"
									type="tel"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="E-mail"
							rules="required|email"
						>
							<b-field
								label="E-mail"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input
									v-model="form.email"
									type="email"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="CPF"
							rules="required|cpf"
						>
							<b-field 
								label="CPF"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input 
									v-model="form.cpf"
									v-mask="['###.###.###-##']"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<b-field
							class="label-switch"
							:class="{ 'input-social': !socialName }"
						>
							<label class="label">
								Nome Social (Opcional)
								<b-switch
									v-model="socialName"
									style="margin-left: 15px;"
									size="is-small"
								/>
							</label>
							<ValidationProvider
								v-if="socialName"
								v-slot="{ errors }"
								name="Nome Social"
								rules="required"
							>
								<b-input
									v-model="form.social_name"
								/>
								<p
									v-if="errors.length > 0"
									class="help is-danger"
								>
									{{ errors[0] }}
								</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
			</fieldset>
			<Card 
				v-else
				bars
				background="blur"
				class="view-card-info"
				size="normal"
			>
				<div>
					<p 
						v-if="form.social_name !== null"
					>
						<b>Nome Social:</b> {{ form.name }}
					</p>
					<p><b>Nome Completo:</b> {{ form.name }} </p>
					<p><b>Telefone:</b> {{ form.phone }} </p>
					<p><b>Email:</b> {{ form.email }} </p>
					<p><b>Número da matrícula:</b> {{ form.matricula }} </p>
				</div>
			</Card>
		</form>
	</ValidationObserver>
</template>

<script>
import '@commons/rules.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Card from '@ebradi/componets/Card'

export default {
	name: 'FormDadosPessoaisComponentEBRADI',
	components: {
		ValidationProvider,
		ValidationObserver,
		Card
	},
	props: {
		view: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			socialName: false,
			form: {
				name: null,
				phone: null,
				email: null,
				cpf: null,
				social_name: null,
				matricula: null
			}
		}
	},
	methods: {
		setData(key, value) {
			if (Object.keys(this.form).includes(key)) {
				this.form[key] = value
			}
		},
		getAllData() {
			return this.form
		},
		getData(key) {
			if (Object.keys(this.form).includes(key)) {
				return this.form[key]
			}

			return null
		},
		async validations() {
			return await this.$refs.form_dados_pessoais.validate()
		},
		clear() {
			this.socialName = false

			this.form = {
				name: null,
				phone: null,
				email: null,
				cpf: null,
				social_name: null,
				matricula: null
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.form-dados-pessoais {
	margin: 84px 0px;

	h2 {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 30px;
	}

	.input-social {
		display: flex;
		height: 100%;
	}

	.label-switch {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	
	&::v-deep .icon.is-right.has-text-danger {
		display: none!important;
	}
}

.view-card-info {
	max-width: 465px;
	margin: 42px 0;

	::v-deep .ebradi-card-body {
		padding: 16px 26px;
		font-size: 16px;
		line-height: 24px;

		b {
			font-weight: 700;
		}
	}
}

</style>
