<template>
	<div class="select-metodo-pagamento">
		<label>{{ label }}</label>
		<div class="metodos-pagamentos">
			<div 
				v-for="(item, index) in metodos_pagamentos"
				:key="index"
				class="radio-select"
				:class="{'active': (item.label == valueSelect.label)}"
			>
				<div
					class="radio-select-icon" 
					v-if="item.icon !== null"
					v-html="item.icon"
				/>
				<div class="metodos-title">
					<span>{{ item.label }}</span>
					<span 
						v-if="item.description !== null"
						class="text-active"
					>
						{{ item.description }}
					</span>
				</div>
				<b-radio
					v-model="valueSelect"
					name="name"
					:native-value="item"
				></b-radio>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SelectMetodoPagamentoComponentEBRADI',
	props: {
		label: {
			type: String,
			required: false,
			default: ''
		},
		value: {
			type: Object,
			required: false,
			default: function () {
				return null
			}
		}
	},
	async mounted() {
		await this.metodos_pagamentos.forEach(async (item) => {
			if (item.icon !== null) {
				await fetch(item.icon)
					.then((res) => res.text())
					.then((res) => {
						item.icon = res
					})
			}
		})
	},
	data() {
		return {
			valueSelect: {},
			metodos_pagamentos: [
				{ 
					label: 'Cartão de crédito',
					description: 'Desconto de 5%',
					icon: require('@ebradi/assets/img/icon-card.svg')
				},
				{ 
					label: 'Cartão (recorrência)',
					description: null,
					icon: require('@ebradi/assets/img/icon-card.svg')
				},
				{ 
					label: 'Pix',
					description: `Desconto de 10% à vista`,
					icon: require('@ebradi/assets/img/icon-pix.svg')
				},
				{ 
					label: 'Boleto bancário',
					description: 'Desconto de 10% à vista',
					icon: require('@ebradi/assets/img/icon-code-bar.svg')
				}
			]
		}
	},
	watch: {
		value(value) {
			this.valueSelect = value
		},
		valueSelect(value) {
			this.$emit('input', value)
		}
	}
}
</script>

<style lang="scss" scoped>
.text-active {
	color: var(--secondary-400);
}

.select-metodo-pagamento {
	margin: 24px 0px;

	label {
        color: #B2B2B2;
	}

	.metodos-pagamentos {
		margin-top: 26px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		& > div {
			display: flex;
			justify-content: space-between;
			border: solid 1px #B2B2B2;
			width: calc(25% - 16px);
			padding: 12px 16px;
			border-radius: 12px;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			align-items: center;

			img {
				width: 24px;
				height: 24px;
			}

			&::v-deep .b-radio {
				margin: 0px;
			}
			
			.metodos-title {
				height: 100%;
				width: 100%;
				padding-left: 12px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				span {
					display: block;
					width: 100%;
					font-size: 12px;
				}
			}
		}
	}
}

.radio-select {
	position: relative;

	.radio-select-icon {
			&::v-deep svg {
				width: 24px!important;
				height: 18px!important;
			}
		}

	&.active {
		border-color: var(--secondary-400)!important;
		color: var(--secondary-400)!important;

		.radio-select-icon {
			fill: var(--secondary-400)!important;

			&::v-deep svg {
				path {
					fill: var(--secondary-400)!important;
				}
			}
		}

		.metodos-title {
			span:first-child {
				font-weight: 600;
			}
		}
	}


	&::v-deep .b-radio.radio {
		&:hover {
			.check {
				border-color: var(--secondary-400)!important;
			}
		}

		input:checked {
			& + .check {
				border-color: var(--secondary-400)!important;

				&::before {
					background: var(--secondary-400)!important;
				}
			}
		}

		.check {
			right: -10px;
			transition: 0.4s;

			&:checked {
				border-color: var(--secondary-400)!important;
			}
		}
	}
}
</style>