<template>
	<div 
		v-if="loading"
		class="loading-page"
	>
		<div>
			<div class="loader_a" /> 
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoadingPageComponentEBRADI',
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	data() {
		return {
			loading: false
		}
	},
	watch: {
		value(value) {
			document.querySelector('html').style.overflow = (value) ? 'hidden' : 'auto'

			this.loading = value
		}
	}
}
</script>

<style lang="scss" scoped>
.loading-page {
	position: fixed;
	left: 0px;
	right: 0px;
	bottom: 0px;
	top: 0px;
	height: 100%;
	width: 100%;
	background: #131313;
	z-index: 999999;
	justify-content: center;
	align-items: center;
	display: flex;

	.loader_a {
		width: 80px;
		padding: 8px;
		aspect-ratio: 1;
		border-radius: 50%;
		background: var(--primary);
		--_m: 
			conic-gradient(#0000 10%,#000), linear-gradient(#000 0 0) content-box;
		-webkit-mask: var(--_m);
				mask: var(--_m);
		-webkit-mask-composite: source-out;
				mask-composite: subtract;
		animation: l3 1s infinite linear;
	}

	@keyframes l3 {
		to {
			transform: rotate(1turn)
		}
	}
}
</style>