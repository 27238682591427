<template>
	<form onsubmit="return false">
		<div class="header-info-form" v-if="!gratuito">
			<h2>Pagamento</h2>
			<Price
				:gratuito="gratuito"
				:price="19229.99"
				:special-price="12219.99"
			/>
		</div>
		<fieldset v-if="!gratuito">
			<SelectMetodoPagamento
				v-model="form.metodo_pagamento"
				label="Métodos de pagamento"
			/>
		</fieldset>
		<component v-if="!gratuito" :is="metodoPagamentoComponent" />
		<div
			v-if="!gratuito && metodo_pagamento"
			class="header-parcelamento"
		>
			<h2>Parcelamento</h2>
			<div class="colunas">
				<div>
					<p>Selecione em quantas vezes gostaria de parcelar seu curso:</p>
					<b-field
						class="select-parcelas"
						message="A partir de 13 parcelas há cobrança de juros"
					>
						<b-select
							placeholder="Parcelas"
							icon=""
						>
							<option
								v-for="option in status"
								:key="option.id"
								:value="option.id"
							>
								{{ option.label }}
							</option>
						</b-select>
					</b-field>
				</div>
				<div>
					<b-field
						label="Código do cupom (opcional)"
					>
						<b-input
							v-model="form.cupom"
							@change="handleCupom"
						/>
					</b-field>
					<a
						href="#"
						class="link-cupom"
					>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0H11.75C12.3467 0 12.919 0.237053 13.341 0.65901C13.7629 1.08097 14 1.65326 14 2.25V11H18V14.75C18 15.612 17.6576 16.4386 17.0481 17.0481C16.4386 17.6576 15.612 18 14.75 18H3.25C2.38805 18 1.5614 17.6576 0.951903 17.0481C0.34241 16.4386 0 15.612 0 14.75V2.25ZM14 16.5H14.75C15.2141 16.5 15.6592 16.3156 15.9874 15.9874C16.3156 15.6592 16.5 15.2141 16.5 14.75V12.5H14V16.5ZM2.25 1.5C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V14.75C1.5 15.716 2.284 16.5 3.25 16.5H12.5V2.25C12.5 2.05109 12.421 1.86032 12.2803 1.71967C12.1397 1.57902 11.9489 1.5 11.75 1.5H2.25ZM4.25 4C4.05109 4 3.86032 4.07902 3.71967 4.21967C3.57902 4.36032 3.5 4.55109 3.5 4.75C3.5 4.94891 3.57902 5.13968 3.71967 5.28033C3.86032 5.42098 4.05109 5.5 4.25 5.5H9.75C9.94891 5.5 10.1397 5.42098 10.2803 5.28033C10.421 5.13968 10.5 4.94891 10.5 4.75C10.5 4.55109 10.421 4.36032 10.2803 4.21967C10.1397 4.07902 9.94891 4 9.75 4H4.25ZM3.5 8.75C3.5 8.55109 3.57902 8.36032 3.71967 8.21967C3.86032 8.07902 4.05109 8 4.25 8H9.75C9.94891 8 10.1397 8.07902 10.2803 8.21967C10.421 8.36032 10.5 8.55109 10.5 8.75C10.5 8.94891 10.421 9.13968 10.2803 9.28033C10.1397 9.42098 9.94891 9.5 9.75 9.5H4.25C4.05109 9.5 3.86032 9.42098 3.71967 9.28033C3.57902 9.13968 3.5 8.94891 3.5 8.75ZM4.25 12C4.05109 12 3.86032 12.079 3.71967 12.2197C3.57902 12.3603 3.5 12.5511 3.5 12.75C3.5 12.9489 3.57902 13.1397 3.71967 13.2803C3.86032 13.421 4.05109 13.5 4.25 13.5H7.25C7.44891 13.5 7.63968 13.421 7.78033 13.2803C7.92098 13.1397 8 12.9489 8 12.75C8 12.5511 7.92098 12.3603 7.78033 12.2197C7.63968 12.079 7.44891 12 7.25 12H4.25Z"
								fill="#33C1D5"
							/>
						</svg>
						Visualize suas próximas parcelas
					</a>
				</div>
			</div>
			<div
				v-if="metodo_pagamento && data_vencimento"
				class="mt-6"
			>
				<SelectCheckBox
					v-model="form.data_vencimento"
					:options="datas_vencimento"
					label="Selecione a data de vencimento mensal:"
					name="data_vencimento"
				/>
			</div>
		</div>
		<div
			v-if="gratuito || (!gratuito && metodo_pagamento)"
			class="mt-6"
		>
			<CheckBox
				scrollToAllow
				v-model="form.contrato_matricula"
				class="mb-4"
				name="contrato_matricula"
				label="Contrato da matrícula"
				:texto="contrato"
			/>
			<CheckBox
				v-model="form.declaracao_graduacao"
				name="issqn"
				label="Declaro que já sou graduado nesta data"
			/>
		</div>
		<p class="text-nao-graduacao">
			Não é graduado? <a href="#">Clique aqui</a>
		</p>
	</form>
</template>

<script>
import Price from '@ebradi/componets/Price'
import SelectMetodoPagamento from '@ebradi/componets/SelectMetodoPagamento'
import CheckBox from '@ebradi/componets/CheckBox'
import FormCartao from '@ebradi/componets/FormCartao'
import SelectCheckBox from '@ebradi/componets/SelectCheckBox'

export default {
	name: 'FormPagamentoComponentEBRADI',
	components: {
		Price,
		SelectMetodoPagamento,
		CheckBox,
		SelectCheckBox
	},
	props: {
    dt_vencimento: {
      type: Array,
      required: false,
			default: () => [],
    },
		gratuito: {
			type: Boolean,
			required: true,
    },
		contrato: {
			type: String,
			required: true,
    },
	},
	data() {
		console.log(this.dt_vencimento);
		return {
			status: [
				{ id: 1, label: '1x de R$1899,05' }
			],
			form: {
				metodo_pagamento: {},
				cupom: null,
				contrato_matricula: false,
				declaracao_graduacao: false,
				data_vencimento: null
			},
			metodo_pagamento: false,
			data_vencimento: false,
			datas_vencimento: this.dt_vencimento.map(item => `Dia ${item}`),
			metodosPagamento: {
				'Cartão de crédito': FormCartao,
				'Cartão (recorrência)': FormCartao,
				'Pix': null,
				'Boleto bancário': null
			},
		}
	},
	computed: {
		metodoPagamentoComponent() {
			if (this.form.metodo_pagamento && this.form.metodo_pagamento?.label) {
				return this.metodosPagamento[this.form.metodo_pagamento.label]
			}

			return null
		}
	},
	watch: {
		'form.metodo_pagamento'() {
			if (this.form.metodo_pagamento && this.form.metodo_pagamento?.label) {
				this.metodo_pagamento = true

				if (this.form.metodo_pagamento?.label !== 'Cartão de crédito') {
					this.data_vencimento = true
				} else {
					this.data_vencimento = false
				}

				return
			}

			this.metodo_pagamento = false
		}
	}
}
</script>

<style lang="scss" scoped>
.header-info-form {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	h2 {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}
}

.text-nao-graduacao {
	font-size: 12px;
	line-height: 24px;
	font-weight: 400;
	margin: 12px 8px;

	a {
		font-weight: 600;
	}
}

.header-parcelamento {
	margin-top: 62px;

	.select-parcelas {
		margin-top: 32px;

		::v-deep .select {
			max-width: 216px!important;
				width: 100%!important;

			select {
				max-width: 216px!important;
				width: 100%!important;
				padding-left: 16px;

				font-weight: 600;
				font-size: 14px;
			}

			&::after {
				display: none!important;
			}

		}
	}

	::v-deep {
		.select.is-empty {
			&::after {
				display: none;
			}
		}
	}

	h2 {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}

	& > .colunas {
		margin-top: 42px;
		display: flex;
		justify-content: space-between;


		& > div:last-child {
			width: 300px;

			::v-deep .field {
				margin-bottom: 26px;
			}
		}
	}
}

.link-cupom {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	transition: 0.4s;

	&:hover {
		opacity: 0.6;
	}
}

</style>
