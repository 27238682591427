<template>
	<section class="container">
		<Card background="block">
			<template #header>
				<HeaderCurso
					:title-curso="curso.title"
					:step="step"
				/>
			</template>
			<div class="ebradi-card-body" v-if="step === 1">
				<HeaderInfo
					:image="require('@ebradi/assets/img/image-3.svg')"
					title="Cadastro"
					sub-title="Confirme seus dados para prosseguirmos"
				>
					<template v-slot:info="{ toggleModalLogin }">
						<h5>Já é aluno? Faça <a  @click="() => toggleModalLogin()">login</a></h5>
					</template>
				</HeaderInfo>
				<FormDadosPessoais
					ref="form_dados_pessoais"
				/>
				<FormEndereco
					ref="form_endereco_residencial"
					label="Endereço residencial"
				/>
				<div>
					<b-field>
						<b-switch
							v-model="hasCNPJ"
						>
							Usar CNPJ como responsável financeiro
						</b-switch>
					</b-field>
					<b-field>
						<b-switch
							v-model="hasEnderecoCobraca"
							:disabled="hasCNPJ"
						>
							Utilizar mesmo endereço para cobrança
						</b-switch>
					</b-field>
				</div>
				<FormDadosResponsavelFinanceiro
					v-if="hasCNPJ"
					ref="form_dados_responsavel_financeiro"
				/>
				<FormEndereco
					v-if="!hasEnderecoCobraca"
					ref="form_endereco_cobrança"
					label="Endereço de cobrança"
				/>
				<div class="btns">
					<b-button
						type="is-secondary"
						class="btn-marge-right"
						rounded
						outlined
					>
						Voltar
					</b-button>
					<b-button
						type="is-secondary"
						rounded
						@click="submit"
					>
						Próximo
					</b-button>
				</div>
			</div>
			<div class="ebradi-card-body" v-if="step === 2">
				<HeaderInfo
					:image="header_info.image"
					:title="header_info.title"
					:sub-title="header_info.sub_title"
				/>
				<div v-if="!resumo" class="box-resumo">
					<div>
						<FormPagamento
							:dt_vencimento="dt_vencimento"
							:gratuito="gratuito"
							:contrato="contrato"
						/>
					</div>
					<div class="btns">
						<b-button
							type="is-secondary"
							class="btn-margin-right"
							rounded
							outlined
						>
							Voltar
						</b-button>
						<b-button
							type="is-secondary"
							rounded
							@click="fakeResumo"
						>
							Concluir
						</b-button>
					</div>
				</div>
			</div>
			<Recaptcha ref="grecaptcha" />
		</Card>
		<LoadingPage v-model="loading" />
	</section>
</template>

<script>
import Card from '@ebradi/componets/Card';
import FormDadosPessoais from '@ebradi/componets/FormDadosPessoais';
import FormDadosResponsavelFinanceiro from '@ebradi/componets/FormDadosResponsavelFinanceiro';
import FormEndereco from '@ebradi/componets/FormEndereco';
import FormPagamento from '@ebradi/componets/FormPagamento';
import HeaderCurso from '@ebradi/componets/HeaderCurso';
import HeaderInfo from '@ebradi/componets/HeaderInfo';
import LoadingPage from '@ebradi/componets/LoadingPage';
import Recaptcha from '@components/Recaptcha';

import { capitalizeIgnoringArticles } from '@commons/helpers';

export default {
	name: 'CheckoutPageEBRADI',
	components: {
		Card,
		FormDadosPessoais,
		FormDadosResponsavelFinanceiro,
		FormEndereco,
		FormPagamento,
		HeaderCurso,
		HeaderInfo,
		LoadingPage,
		Recaptcha,
	},
	data() {
		return {
			hasCNPJ: false,
			loading: false,
			hasEnderecoCobraca: true,
			curso: {
				title: ''
			},
			form: {
				captcha: '',
			},
			step: 1,
			header_info: {
				title: 'Pagamento e Contrato',
				sub_title: 'Concluindo esta etapa você terá acesso ao portal',
				image: require('@ebradi/assets/img/image-1.svg')
			},
			resumo: false,
			dt_vencimento: [],
			gratuito: false,
			contrato: '',
		}
	},
	mounted() {
		this.loading = true;

		if (!window.grecaptcha) {
			const recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
			document.head.appendChild(recaptchaScript);
			recaptchaScript.onload = () => {
				window.grecaptcha.ready(() => {
					this.authenticate();
				});
			};
    } else {
      this.authenticate();
    }
	},
	watch: {
		hasCNPJ(value) {
			if (value === true) {
				this.hasEnderecoCobraca = false;
			} else {
				this.hasEnderecoCobraca = true;
			}
		}
	},
	methods: {
		async authenticate() {
			this.loading = true;

			try {
				this.form.captcha = await this.$refs.grecaptcha.generate();
			} catch (error) {
				this.toastOpen('Recaptcha inválido!', 'is-danger');

				return;
			}

			const	captcha = this.form.captcha;
			const payload = {
				curs_id: this.$route.params.curs_id,
				nens_id: this.$route.params.nens_id,
			}

			await this.$store.dispatch('sessao/authCheckout', captcha)
				.catch(() => {
					this.toastOpen('Erro na autenticação do servidor', 'is-danger');
					return;
				})

			this.$store.dispatch('ebradi/getCurso', payload)
				.then(([res]) => {
					this.curso.title = capitalizeIgnoringArticles(res.curs_nome);
				})
				.catch(() => {
					this.toastOpen('Erro ao recuperar dados do curso', 'is-danger');
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async submit() {
			this.loading = true

			let errors = 0

			if (
				!await this.$refs.form_dados_pessoais.validations() &&
				!await this.$refs.form_endereco_residencial.validations()
			) {
				errors = 2
			}

			if (
				!this.hasEnderecoCobraca &&
				!await this.$refs.form_endereco_cobrança.validations()
			) {
				errors++
			}

			if (
				this.hasCNPJ &&
				!await this.$refs.form_dados_responsavel_financeiro.validations()
			) {
				errors++
			}

			if (errors === 0) {
				const curs_id = this.$route.params.curs_id;
				const payload = {
					curs_id,
					nens_id: this.$route.params.nens_id,
					dados_pessoais: this.$refs?.form_dados_pessoais.getAllData(),
					endereco: this.$refs?.form_endereco_residencial.getAllData(),
					responsavel_financeiro: this.$refs.form_dados_responsavel_financeiro?.getAllData(),
					endereco_cobrança: this.$refs.form_endereco_cobrança?.getAllData(),
				};

				this.$store.dispatch('ebradi/inscrever', payload)
					.then(async (res) => {
						const { insc_id } = res;
						this.step = 2;
						await this.metodosPagamento(insc_id);
					})
					.catch(() => {
						this.toastOpen('Erro ao tentar se inscrever no processo seletivo', 'is-danger');
						this.loading = false;
					});
			} else {
				this.loading = false;
			}
		},

		toastOpen(message, type) {
			this.$buefy.toast.open({
				message,
				type,
				duration: 4000,
			});
		},

		fakeResumo() {
			this.resumo = true
			this.header_info.title = 'Resumo da compra'
		},

		async metodosPagamento(cand_id) {
			const payload = {
				cand_id,
			};

			this.$store.dispatch('ebradi/getPagamentos', payload)
				.then(async (res) => {
					const { data: { curso_gratuito, dias_vencimento } } = res;
					this.gratuito = curso_gratuito;
					if (dias_vencimento.length) {
						this.dt_vencimento = dias_vencimento;
					}
					if (curso_gratuito) {
						this.header_info = {
							title: 'Contrato e termos',
							sub_title: 'Concluindo esta etapa você terá acesso ao portal',
							image: require('@ebradi/assets/img/image-5.svg'),
						};
						await this.$store.dispatch('ebradi/getContrato', payload)
							.then(({ contrato }) => {
								this.contrato = contrato;
							})
							.catch(() => {
								this.toastOpen('Erro ao buscar dados do contrato', 'is-danger');
							});
					}

					this.loading = false;
				})
				.catch(() => {
					this.toastOpen('Erro ao tentar buscar métodos de pagamento', 'is-danger');
				});
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	padding: 50px 0;
	max-width: 1150px!important;
	padding-bottom: 400px;
}

.ebradi-card-body {
	padding: 42px 82px;

	.btns {
		text-align: center;
		margin-top: 82px;
		margin-bottom: 22px;

		.btn-marge-right {
			margin-right: 32px;
		}
	}
}

.box-resumo {
	margin-top: 88px;
}

.card-custom-margin {
	padding: 32px 44px;

	h2 {
		color: var(--secondary-400);
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
	}


	&:last-child {
		padding-top: 0px;
	}
}
</style>
