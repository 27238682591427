<template>
	<ValidationObserver
		ref="form_dados_responsavel_financeiro"
	>
		<form
			class="form-dados-responsavel-financeiro"
			@submit="() => false"
		>
			<h2>Dados do responsável financeiro</h2>
			<fieldset>
				<div class="columns is-multiline">
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="Razão Social"
							rules="required|max:100|min:3"
						>
							<b-field
								label="Razão Social"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input v-model="form.company_name" />
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="CNPJ"
							rules="required|cnpj|max:18|min:18"
						>
							<b-field
								label="CNPJ"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input 
									v-model="form.cnpj"
									v-mask="['##.###.###/####-##']"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="Email"
							rules="required|email"
						>
							<b-field
								label="Email"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input 
									v-model="form.email"
									type="email"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="Inscrição Municipal"
							rules="required"
						>
							<b-field
								label="Inscrição Municipal"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input v-model="form.municipal_registration" />
							</b-field>
						</ValidationProvider>
					</div>
					<div class="column is-4">
						<ValidationProvider
							v-slot="{ errors }"
							name="Inscrição Estadual"
							rules="required"
						>
							<b-field
								label="Inscrição Estadual"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input v-model="form.state_registration" />
							</b-field>
						</ValidationProvider>
					</div>
				</div>
				<ValidationProvider
					v-slot="{ errors }"
					name="Regime Fiscal"
					rules="required"
				>
					<SelectCheckBox 
						v-model="form.tax_regime"
						:options="optionsRegimeFiscal"
						label="Regime Fiscal"
						:errors="errors"
						name="tax_regime"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					name="Substituto Tributário do ISSQN"
					rules="required"
				>
					<CheckBox
						v-model="form.issqn"
						name="issqn"
						label="Substituto Tributário do ISSQN"
						:errors="errors"
					/>
				</ValidationProvider>
			</fieldset>
		</form>
	</ValidationObserver>
</template>

<script>
import CheckBox from '@ebradi/componets/CheckBox'
import SelectCheckBox from '@ebradi/componets/SelectCheckBox'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
	name: 'FormDadosResponsavelFinanceiroCompoentEBRADI',
	components: {
		SelectCheckBox,
		CheckBox,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			optionsRegimeFiscal: ['Simples Nacional', 'MEI', 'Lucro Resumido', 'Lucro Real'],
			form: {
				company_name: null,
				cnpj: null,
				email: null,
				municipal_registration: null,
				state_registration: null,
				tax_regime: null,
				issqn: false
			}
		}
	},
	methods: {
		setData(key, value) {
			if (Object.keys(this.form).includes(key)) {
				this.form[key] = value
			}
		},
		getAllData() {
			return this.form
		},
		getData(key) {
			if (Object.keys(this.form).includes(key)) {
				return this.form[key]
			}
		},
		async validations() {
			return await this.$refs.form_dados_responsavel_financeiro.validate()
		},
		clear() {
			this.form = {
				company_name: null,
				cnpj: null,
				email: null,
				municipal_registration: null,
				state_registration: null,
				tax_regime: null,
				issqn: false
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.form-dados-responsavel-financeiro {
	margin: 84px 0px;

	fieldset {
		display: contents;
	}

	h2 {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 30px;
	}

	&::v-deep .icon.is-right.has-text-danger {
		display: none!important;
	}
}
</style>